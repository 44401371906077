import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Tooltip, Typography } from '@mui/material';
import If from '@components/If';
import { GetFoldersResponse, TemplateFile, TemplateFolder } from '@core/types';
import useSx from './sx';

export default function FolderView({
  itemArray,
  onTemplateClick,
}: {
  itemArray: GetFoldersResponse['root'];
  onTemplateClick: (id: TemplateFile['id']) => void;
}) {
  const INITIAL_BREADCRUMBS = [{ name: 'Plantillas', displayArray: itemArray }];

  const [display, setDisplay] = useState<TemplateFolder['subItems']>(itemArray);
  const [breadcrumbs, setBreadcrumbs] = useState(INITIAL_BREADCRUMBS);
  const sx = useSx();

  function navigateFolder(displayArray: TemplateFolder) {
    setBreadcrumbs([
      ...breadcrumbs,
      { name: displayArray.name, displayArray: displayArray.subItems },
    ]);
    setDisplay(displayArray.subItems);
  }

  function breadcrumbClick(displayArray: TemplateFolder['subItems'], index: number) {
    setBreadcrumbs(breadcrumbs.splice(0, index + 1));
    setDisplay(displayArray);
  }

  useEffect(() => {
    setBreadcrumbs(INITIAL_BREADCRUMBS);
    setDisplay(itemArray);
  }, [itemArray]);

  return (
    <Box>
      <Box sx={sx.breadcrumbsContainer}>
        {breadcrumbs.map(({ name, displayArray }, index) => {
          return index === breadcrumbs.length - 1 ? (
            <Typography key={name}>{name}</Typography>
          ) : (
            <>
              <Typography
                key={name}
                onClick={() => breadcrumbClick(displayArray, index)}
                sx={sx.clickableBreadcrumb}
              >
                {name}
              </Typography>
              <Typography>{`>`}</Typography>
            </>
          );
        })}
      </Box>
      <Box sx={sx.foldersView}>
        {Object.keys(display).length ? (
          Object.keys(display).map((key) => {
            return (
              <Box
                sx={sx.rowContent}
                key={key}
                onClick={
                  display[key].isFile
                    ? () => onTemplateClick(display[key].id)
                    : () => navigateFolder(display[key] as TemplateFolder)
                }
              >
                <If condition={display[key].isFile} fallback={<FolderIcon sx={sx.coloredIcon} />}>
                  <InsertDriveFileIcon sx={sx.Icon} />
                </If>
                <Typography sx={sx.templateName}>{display[key].name}</Typography>
                <If condition={display[key].organizationWide}>
                  <Tooltip title="Organizacíon">
                    <ApartmentIcon sx={sx.organizationIcon} />
                  </Tooltip>
                </If>
                <Typography sx={sx.templateDate}>
                  {dayjs(display[key].modifiedDate).format('DD/MM/YYYY h:mm A')}
                </Typography>
              </Box>
            );
          })
        ) : (
          <Box sx={sx.rowContent}>
            <Typography sx={sx.templateName}>
              No se ha encontrado ningún archivo con ese nombre dentro de esta carpeta
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

import React, { useEffect, useRef, useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import { initialTemplate } from 'app/features/reports/add/constants/initialTemplate';
import createDoctorNameParagraph from 'app/features/reports/add/utils/createDoctorNameParagraph';
import createFooterDiv from 'app/features/reports/add/utils/createFooterDiv';
import createProfessionalInfoParagraphs from 'app/features/reports/add/utils/createProfessionalInfoParagraphs';
import createQRCodeDiv from 'app/features/reports/add/utils/createQRCodeDiv';
import createSignatureImage from 'app/features/reports/add/utils/createSignatureImage';
import getAbsoluteHeight from 'app/features/reports/add/utils/getAbsoluteHeight';
import splitPage from 'app/features/reports/add/utils/splitPage';
import { useGetSignature } from 'app/features/users/edit/hooks/useSignature';
import HeightSandBox from './HeightSandbox';
import Page from './Page';
import PreviewProps from './PreviewProps';
import useSx from './sx';

const pageConfig = {
  size: {
    width: 816,
    height: 1054,
  },
  position: {
    x: 50,
    y: 150,
  },
  padding: {
    top: 150,
    right: 50,
    bottom: 80,
    left: 50,
  },
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontSize: 14,
  lineHeight: 1,
};
const MAX_ALLOWED_SIZE_FOR_CONTENT =
  pageConfig.size.height - pageConfig.padding.top - pageConfig.padding.bottom;

const getTotalHeight = (element: HTMLElement) => {
  let totalHeight = 0;
  for (let i = 0; i < element.childNodes.length; i++) {
    const child = element.childNodes[i] as HTMLElement;
    if (child.nodeType === Node.ELEMENT_NODE) {
      // Sumar la altura del hijo y su margen superior e inferior
      totalHeight += getAbsoluteHeight(child);
    }
  }

  return totalHeight;
};

const handlePageSplitting = (element: HTMLElement, maxHeight: number, footerDiv: HTMLElement) => {
  let splittedNodes = [];
  const pages = [];
  do {
    splittedNodes = splitPage(element, maxHeight, pageConfig);

    const newPageContent = splittedNodes.reduce(
      (htmlContent, node) => htmlContent + (node instanceof Element ? node.outerHTML : ''),
      '',
    );
    pages.push(element.innerHTML);
    element.innerHTML = newPageContent;
  } while (splittedNodes.length > 0);

  const formattedPages = pages.map((page) => {
    const temp = document.createElement('div');
    temp.innerHTML = page;
    temp.querySelector('#footer-editor')?.remove();
    return temp.innerHTML;
  });

  formattedPages[formattedPages.length - 1] += footerDiv.outerHTML;

  return formattedPages;
};

const Preview = ({ content, customBackground, isLoading }: PreviewProps) => {
  const preview = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const [pagesContent, setPagesContent] = useState<string[]>([]);

  const { data } = useGetSignature();
  const { fullName } = useProfile();

  // Función debounced que actualiza el contenido del preview
  const updatePreview = useCallback(
    debounce(async (newContent: string) => {
      const createQrCode = async (containerElement: HTMLElement) => {
        const qrCode = await createQRCodeDiv(process.env.REACT_APP_ADB2C_REDIRECT_URI!);
        containerElement.appendChild(qrCode);
      };
      const createCustomDivFooter = () => {
        const divFooter = createFooterDiv();

        // Removing position absolute by defect, we are using flex in this logic
        divFooter.style.position = 'relative';
        divFooter.style.marginTop = 'auto';

        return divFooter;
      };

      const appendSignatureImage = (containerElement: HTMLElement) => {
        if (data?.signature) {
          const image = createSignatureImage(data!.signature);
          containerElement.appendChild(image);
        }
      };

      const appendDoctorNameParagraph = (containerElement: HTMLElement) => {
        const professionalNameParagraph = createDoctorNameParagraph(fullName || '');
        containerElement.appendChild(professionalNameParagraph);
      };
      const appendDoctorProfessionalInfo = (containerElement: HTMLElement) => {
        if (data?.professionalInfo) {
          const professionalInfoParagraphs = createProfessionalInfoParagraphs(
            data.professionalInfo,
          );
          professionalInfoParagraphs.forEach((paragraph) => {
            containerElement.appendChild(paragraph);
          });
        }
      };

      //This is needed because by defect in the editor the lineheight is set 1 by the parent, so we need to set it to 1 in the children
      // in case that they don't have a lineheight set
      const setLineHeihtToChildren = (element: HTMLElement) => {
        element.childNodes.forEach((node) => {
          if (node instanceof HTMLElement && !node.style.lineHeight) node.style.lineHeight = '1';
        });
      };

      if (preview.current) {
        const divFooter = createCustomDivFooter();
        await createQrCode(divFooter);

        appendSignatureImage(divFooter);

        appendDoctorNameParagraph(divFooter);

        appendDoctorProfessionalInfo(divFooter);

        const div = document.createElement('div');
        div.innerHTML = newContent;
        setLineHeihtToChildren(div);

        div.appendChild(divFooter);
        preview.current.innerHTML = initialTemplate + div.innerHTML;

        //Logica para checar overflowing

        // Calcular la altura manualmente basada en los hijos
        const totalHeight = getTotalHeight(preview.current);

        const pages = [];

        if (totalHeight > MAX_ALLOWED_SIZE_FOR_CONTENT) {
          const newPages = handlePageSplitting(
            preview.current,
            MAX_ALLOWED_SIZE_FOR_CONTENT,
            divFooter,
          );
          pages.push(...newPages);
        } else {
          pages.push(preview.current.innerHTML);
        }
        setPagesContent(pages);

        setLoading(false); // Se detiene el loading cuando la operación finaliza
      }
    }, 500), // 500ms de debounce
    [data?.professionalInfo, data?.signature, fullName],
  );

  useEffect(() => {
    setLoading(true); // Comienza el loading al cambiar el contenido
    updatePreview(content); // Llama la función debounced
  }, [content, updatePreview]);

  const sx = useSx();

  return (
    <>
      <Box sx={sx.root}>
        <If condition={loading || isLoading}>
          <Box sx={sx.loadingContainer}>
            <CircularProgress sx={sx.loading} />
          </Box>
        </If>
        <HeightSandBox
          customBackground={customBackground}
          heightSandboxRef={preview}
          pageConfig={pageConfig}
        />
        {pagesContent.map((page, index) => (
          <Page
            key={index}
            pageConfig={pageConfig}
            customBackground={customBackground}
            content={content}
          />
        ))}
      </Box>
    </>
  );
};

export default Preview;

import { useQuery } from '@tanstack/react-query';
import { getFolders } from '../api/getFolders';
import { SearchParams } from './useFilters';

export const QUERY_KEY = 'templateList';

const useGetFolders = (filters: SearchParams) => {
  const { data: folders, ...rest } = useQuery({
    queryKey: [QUERY_KEY, filters],
    queryFn: () => getFolders(filters),
  });

  return {
    folders,
    ...rest,
  };
};

export default useGetFolders;

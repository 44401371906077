import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Title from '@components/Title';
import Actions from '../components/Actions';
import FolderView from '../components/FolderView';
import useTemplatesState from './useTemplatesState';

const Templates = () => {
  const { folders, deviceWidth } = useTemplatesState();
  const navigate = useNavigate();

  const handleTemplateClick = (id: number) => {
    navigate(`/templates/${id}`);
  };

  return (
    <Container sx={{ margin: 0 }} maxWidth={deviceWidth} disableGutters>
      <Title text="Plantillas" />
      <Actions />
      <FolderView itemArray={folders?.root || {}} onTemplateClick={handleTemplateClick} />
    </Container>
  );
};

export default Templates;

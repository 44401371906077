import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import useOrganizationTemplateImage from '@core/hooks/useOrganizationTemplateImage';
import blobToBase64 from '@core/utils/createBase64FromBlob';
import Preview from './Preview';
import PreviewTextEditorProps from './PreviewTextEditorProps';
import TextEditor from './TextEditor';

const PreviewTextEditor = ({
  textEditorRef,
  error,
  initialContent,
  helperText,
}: PreviewTextEditorProps) => {
  const [editorContent, setEditorContent] = useState('');
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);
  const [templateImageUrl, setTemplateImageUrl] = useState('');
  const { isFetching, ...templateImageResponse } = useOrganizationTemplateImage(templateId);

  useEffect(() => {
    const ref = subscribe('evodicom.report.changeTemplate', (data) => {
      setTemplateId(data.templateId);
    });

    return () => {
      unsubscribe('evodicom.report.changeTemplate', ref);
    };
  }, []);

  useEffect(() => {
    const loadImage = async () => {
      if (
        templateImageResponse.status === 'success' &&
        (templateImageResponse.renderedImage?.size ?? 0) > 0
      ) {
        const imageB64 = await blobToBase64(templateImageResponse.renderedImage!);
        setTemplateImageUrl(imageB64);
      }
    };

    loadImage();
  }, [templateImageResponse.renderedImage, templateImageResponse.status]);

  useEffect(() => {
    textEditorRef.current?.setContent(initialContent || '');
  }, []);

  return (
    <Box>
      <Box
        flex="1"
        display="flex"
        gap="10px"
        sx={{ '.texteditor-container': { flex: 1, width: '40%' } }}
        margin="auto"
        justifyContent="center"
      >
        <TextEditor
          error={error}
          helperText={helperText}
          textEditorRef={textEditorRef}
          onChange={(newContent) => {
            setEditorContent(newContent);
          }}
        />
        <Preview
          isLoading={isFetching}
          customBackground={templateImageUrl}
          content={editorContent}
        />
      </Box>
    </Box>
  );
};

export default PreviewTextEditor;

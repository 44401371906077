import React, { useMemo } from 'react';
import { RibbonButton, getButtons } from 'roosterjs-react';
import useProfile from '@core/hooks/useProfile';
import { GetStudyResponse } from '@core/types';
import { OrganizationTemplateModel } from '@core/types/OrganizationTemplate';
import { isAllowedFont } from '@core/utils/fonts';
import { useGetSignature } from 'app/features/users/edit/hooks/useSignature';
import { ButtonFactoryProps, getRegisteredButtons } from '../buttons/buttonRegistryFactory';
import '../buttons/registry';
import extractElementFromArray from '../utils/extractElementFromArray';

const filterFonts = (button: RibbonButton<string>) => {
  const fonts = (
    button.dropDownMenu?.items ? Object.entries(button.dropDownMenu.items) : []
  ).filter((fontEntry) => isAllowedFont(fontEntry[1]));

  if (fonts.length > 0) {
    button.dropDownMenu!.items = Object.fromEntries(fonts);
  }
};

const useGetFilteredButtons = (
  themeMode: string,
  studyInfo: GetStudyResponse,
  templates: OrganizationTemplateModel[],
) => {
  const buttons = getButtons() as unknown as RibbonButton<string>[];

  const { fullName } = useProfile();
  const { data: signatureData } = useGetSignature();

  const filteredButtons = useMemo(() => {
    const ribbonButtons: RibbonButton<string>[] = buttons.map((button) => {
      const newButton = { ...button };
      if (newButton.key === 'buttonNameFont') {
        filterFonts(button);
      }

      return newButton;
    });

    const buttonProps: ButtonFactoryProps = {
      themeMode,
      userInfo: {
        fullName: fullName,
        professionalInfo: signatureData?.professionalInfo,
        signature: signatureData?.signature,
      },
      studyInfo: { shareUrl: studyInfo?.shareUrl },
      templates,
    };

    const buttonsToRemove = [
      'buttonNameQuote',
      'buttonNameInsertLink',
      'buttonNameRemoveLink',
      'buttonNameSubscript',
      'buttonNameStrikethrough',
      'buttonNameCode',
      'buttonNameLtr',
      'buttonNameRtl',
      'buttonNameInsertTable',
    ];

    const filteredRibbonButtons = ribbonButtons.filter(
      (button) => !buttonsToRemove.includes(button.key),
    );

    const [customButtons, lineHeightButton] = extractElementFromArray(
      getRegisteredButtons(buttonProps),
      (elem) => elem.key === 'buttonNameSpacing',
    );

    const fontDownIndex =
      filteredRibbonButtons.findIndex((button) => button.key === 'buttonNameDecreaseFontSize') + 1;

    return [
      ...filteredRibbonButtons.slice(0, fontDownIndex),
      lineHeightButton,
      ...filteredRibbonButtons.slice(fontDownIndex),
      ...customButtons,
    ];
  }, [themeMode, buttons, fullName, studyInfo?.shareUrl, signatureData, templates]);

  return { filteredButtons };
};

export default useGetFilteredButtons;

import { IEditor, EditorPlugin, PluginEvent, PluginEventType } from 'roosterjs';

class UpdateStatePlugin implements EditorPlugin {
  private editor: IEditor | null | undefined;

  private onInputChange: (value: string) => void;

  constructor(onInputChange: (value: string) => void) {
    this.onInputChange = onInputChange;
  }

  getName() {
    return 'UpdateStatePlugin';
  }

  initialize(editor: IEditor) {
    this.editor = editor;
  }

  dispose() {
    this.editor = null;
  }

  onPluginEvent(event: PluginEvent) {
    const doc = this.editor?.getDocument();
    this.onInputChange(doc?.querySelector('.rooster')?.innerHTML || '');
    if (event.eventType === 10) {
      event.sanitizingOption.additionalAllowedAttributes = ['white-space'];
      event.sanitizingOption.cssStyleCallbacks['background-color'] = () => false;
      event.sanitizingOption.cssStyleCallbacks.position = () => true;
      event.sanitizingOption.cssStyleCallbacks.overflow = () => true;
      event.sanitizingOption.cssStyleCallbacks.color = () => true;
      event.sanitizingOption.cssStyleCallbacks['white-space'] = () => false;
    }
  }

  setContent(value: string) {
    this.editor?.setContent(value);
  }
}

export default UpdateStatePlugin;

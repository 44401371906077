import { RoleName } from '@core/types';
import viewerConfig from '../../config/viewer-config';

export interface DeleteSeriesHandler {
  (
    displaySet: {
      StudyInstanceUID: string;
      SeriesInstanceUID: string;
      Modality: string;
      SOPInstanceUID: string;
    },
    existingSeriesCount: number,
  ): Promise<boolean>;
}

export interface ConfigOptions {
  studyInstanceUid: string;
  isAnon: boolean;
  deleteSeriesEnabled: boolean;
  role: RoleName | undefined;
  deleteSeriesHandler: DeleteSeriesHandler;
  routerBasename: string;
  generatedReportIds: Array<string> | null;
}

const config = ({
  studyInstanceUid,
  isAnon,
  deleteSeriesEnabled,
  deleteSeriesHandler,
  role,
  routerBasename,
  generatedReportIds,
}: ConfigOptions) => {
  const preConfiguration = viewerConfig(generatedReportIds ?? []);
  return {
    ...preConfiguration,
    evodicomConfig: {
      ...preConfiguration.evodicomConfig,
      isAnon,
      role,
      customActions: {
        deleteSeries: {
          enabled: deleteSeriesEnabled,
          callback: deleteSeriesHandler,
        },
      },
      studyInstanceUIDs: studyInstanceUid,
      toolbar: {
        enableMobileToolbar: window.screen.width < 900,
        mobile: {
          // Add the button id to the corresponding array to use this function
          renderOutsideFoldable: ['StackScroll', 'Wwwc', 'Data'],
          hide: ['printPhoto'],
        },
      },
    },
    routerBasename,
  };
};

export default config;

import { ThemeOptions } from '@mui/material';

// prettier-ignore
const fontFamily = 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"';
const borderRadiusDefault = 16;

const baseTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 769,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily,
    fontSize: 12.25,
    button: {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  spacing: 4,
  shape: {
    borderRadius: borderRadiusDefault,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault * 2,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '1rem',
          minWidth: 'fit-content',
          height: '36px',
          borderRadius: 25,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          '&.Mui-disabled': {
            color: 'white',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {},
      },
    },
    // FIX: Temporary fix to align the TextField label. This change adjusts the label style directly,
    // even though the root cause lies in the input style due to our extensive customizations in the theme.
    // Proper solution involves updating the input styles, but this requires a thorough review of all theme customizations
    // to avoid breaking other components.
    // TODO: Refactor to correctly adjust input styles and remove this workaround.
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-7px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault * 2,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault * 2,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {},
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
        head: {
          fontWeight: 'bold',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          padding: '0.2rem 0.5rem',
          '& .MuiInputBase-input:-webkit-autofill': {
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'white',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: 'none',
          },
          '::before': {
            borderBottom: 'none',
          },
          '::after': {
            borderBottom: 'none',
          },
        },
      },
    },
  },
};

export default baseTheme;

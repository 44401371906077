import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  breadcrumbsContainer: {
    marginTop: '1rem',
    display: 'flex',
    gap: '.5rem',
  },
  clickableBreadcrumb: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  Icon: {
    height: '2rem',
    width: '2rem',
    gridRow: 'span 2',
  },
  coloredIcon: {
    height: '2rem',
    width: '2rem',
    color: (theme) => theme.palette.primary.main,
    gridRow: 'span 2',
  },
  organizationIcon: {
    height: '1.5rem',
    width: '1.5rem',
    gridRow: 'span 2',
  },
  foldersView: {
    marginTop: '1rem',
    background: (theme) => theme.palette.shade?.[200],
    borderRadius: '8px',
    overflow: 'hidden',
    marginBottom: {
      xs: '100px',
      md: 0,
    },
  },
  rowContent: {
    display: {
      xs: 'grid',
      md: 'flex',
    },
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    alignItems: 'center',
    minHeight: '55px',
    padding: '1rem',
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    gap: '.5rem 1rem',
    cursor: 'pointer',
    '&:hover': {
      background: (theme) => theme.palette.shade?.[100],
    },
    gridTemplateColumns: 'auto 1fr auto',
  },
  templateName: {
    flex: 1,
    gridColumn: '2',
  },
  templateDate: {
    gridColumn: '2',
  },
});

export default useSx;

import { SubscriptionsDetails } from "../components/Subscription/SubscriptionGeneralProps";

const transformToPieChartData = (details: SubscriptionsDetails) => {
  const availableValue = details?.userPlan?.settings?.studiesIncluded || 0;
  const usedValue = details?.usage?.totalStudiesCount || 0;
  const adicionalValue = Math.max(0, usedValue - availableValue);
  return [
      { name: 'Disponible', value: availableValue, total: availableValue, color: '#149511' },
      { name: 'Utilizado', value: usedValue, total: usedValue, color: '#0E70FF' },
      { name: 'Adicional', value: adicionalValue, total: adicionalValue, color: '#d92626' }
  ];
};

export default transformToPieChartData;
const getPageStyles = (
  pageConfig: {
    size: {
      width: number;
      height: number;
    };
    padding: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    };
  },
  customBackground: string,
) => ({
  backgroundImage: `url(${customBackground})`,
  width: `${pageConfig.size.width}px`,
  height: `${pageConfig.size.height}px`,
  paddingTop: `${pageConfig.padding.top}px`,
  paddingRight: `${pageConfig.padding.right}px`,
  paddingBottom: `${pageConfig.padding.bottom}px`,
  paddingLeft: `${pageConfig.padding.left}px`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  wordWrap: 'break-word' /* Permite que el texto se ajuste */,
  overflowWrap: 'break-word' /* Es la versión más moderna */,
  whiteSpace: 'normal' /* Asegura que el texto haga un wrap en múltiples líneas */,
  overflow: 'hidden',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  bgcolor: 'white',
});

export default getPageStyles;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Box } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import Title from '@components/Title';
import useSx from '../sx';
import { HeaderProps } from './HeaderProps';

const Header: React.FC<HeaderProps> = ({ onTransferAccountClick }) => {
  const styles = useSx();
  const navigate = useNavigate();

  return (
    <Box sx={styles.titleContainer}>
      <Title text="Detalle de la Suscripción" />
      <Box sx={styles.buttonContainer}>
        {/* TODO: This section of code has been temporarily commented out as the feature it implements is being hidden for the time being. The decision to disable this feature comes as we are currently reassessing and clarifying our billing process. We intend to revisit and potentially re-enable this functionality once we have a more definitive understanding of the billing mechanisms and how they should be accurately implemented within our system. This measure ensures that we maintain the integrity and reliability of our billing operations while we make necessary adjustments.
        <ActionButton
          color="secondary"
          variant="outlined"
          text="Cambiar Paquete"
          icon={<AutorenewIcon />}
          sx={styles.buttonAction}
          onClick={() => navigate('/settings/subscription/change-subscription')}
        /> 
        */}
        <ActionButton
          color="secondary"
          variant="outlined"
          text="Transferir cuenta"
          icon={<SwapHorizIcon />}
          sx={styles.buttonAction}
          onClick={onTransferAccountClick}
        />
        <ActionButton
          color="secondary"
          variant="outlined"
          text="Ver métodos de pago"
          icon={<CreditCardIcon />}
          sx={styles.buttonAction}
          disabled
        />
        <ActionButton
          color="secondary"
          variant="outlined"
          text="Ver facturas recientes"
          icon={<DescriptionIcon />}
          sx={styles.buttonAction}
          disabled
        />
      </Box>
    </Box>
  );
};

export default Header;
